import React from "react";
import { format, subHours } from "date-fns";
import svLocale from "date-fns/locale/sv";

import Box from "src/components/UI/Structure/Box";
import Button from "src/components/UI/Button";

const BuyButton = ({
  size,
  status,
  ticketUrl,
  ticketReleaseDate,
  artistUrl,
}) => {
  const today = Date.now();
  return (
    <Button
      label="Visa artist"
      size={size}
      border={
        (status === "open" && "primary") ||
        (status === "doors" && "primary") ||
        (status === "free" && "primary") ||
        (status === "soon" && "primary") ||
        (status === "sold_out" && "warning") ||
        null
      }
      link={status === "none" ? "/artister/" + artistUrl : ticketUrl}
      linkTarget={ticketUrl && status !== "none" ? "_blank" : null}
      width={["auto", null, null, 1]}
    >
      <Box width={1} textAlign="center">
        {(status === "open" && "Köp biljett") ||
          (status === "doors" && "Inget förköp") ||
          (status === "free" && "Fri entré") ||
          (status === "soon" &&
            (subHours(new Date(ticketReleaseDate), 2) > today
              ? `Släpps ${format(new Date(ticketReleaseDate), "dd/M", {
                  locale: svLocale,
                })}`
              : "Köp biljett")) ||
          (status === "sold_out" && "Slutsålt") ||
          "Visa artist"}
      </Box>
    </Button>
  );
};

export default BuyButton;
