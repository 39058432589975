import styled from "styled-components";

import Link from "src/components/UI/Link";

const LinkStyles = styled(Link)`
  color: black;
  text-decoration: none;
`;

export default LinkStyles;
