import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const ListItemStyles = styled.div`
  width: 100%;
  font-size: ${props =>
    props.size === 'small'
      ? props.theme.fontSizes[3]
      : props.theme.fontSizes[3]};
  position: ${props => (props.sticky ? 'sticky' : 'relative')};
  z-index: ${props => (props.sticky ? '1' : '0')};
  background-color: white;
  top: 0;
  margin-top: 0;
  padding: ${props =>
    props.size === 'small' ? props.theme.fontSizes[2] : props.theme.space[2]}
    ${props => props.theme.space[4]};


  ${media.md`
    padding: ${props =>
      props.size === 'small'
        ? `calc(${props.theme.space[1]} * 1.5)`
        : props.theme.space[2]} ${props => props.theme.space[4]};
  `}

  ${media.lg`
    padding: ${props =>
      props.size === 'small'
        ? `calc(${props.theme.space[1]} * 1.5)`
        : props.theme.space[2]} ${props => props.theme.space[4]};
    border: none;
    margin-top: ${props =>
      props.size !== 'small' && props.label && props.theme.space[7]};
          font-size: ${props =>
            props.size === 'small'
              ? props.theme.fontSizes[2]
              : props.theme.fontSizes[3]};

  `}

  ${media.xl`
    padding: ${props =>
      props.size === 'small'
        ? props.theme.space[2]
        : props.theme.space[2]} ${props => props.theme.space[4]};
    font-size: ${props =>
      props.size === 'small'
        ? props.theme.fontSizes[3]
        : props.theme.fontSizes[5]};

  `}

  &:nth-of-type(even) {
    background-color: ${props => props.theme.colors.gray.default};
    border-top: 1px solid ${props => props.theme.colors.gray.dark};
    border-bottom: 1px solid ${props => props.theme.colors.gray.dark};
  }

  &:first-of-type {
    border-top: ${props =>
      props.sticky ? `1px solid ${props.theme.colors.gray.dark}` : 'none'};

    ${media.lg`
        border-top: none;
    `}
  }

  &:last-of-type {
    border-bottom: ${props =>
      props.size === 'small'
        ? `1px solid ${props.theme.colors.gray.dark}`
        : `none`};
  }

  /* Small size list with label */
  ${props =>
    props.label &&
    props.size === 'small' &&
    `
      border: none !important;
      background:${props.theme.colors.dark} !important;
      border:none;
      color:${props.theme.colors.light};

      a {
        color:${props.theme.colors.light};
      }
    `}

  .list-content {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .list-content span:not(.country-code) {
    ${props => props.theme.mixins.textElipsis}
    display: block;
  }

  .list-content.list-content--label,
  .list-content.list-content--title {
    font-weight: 500;
  }

  .list-content.list-content--label {
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: ${props => props.theme.fontSizes[2]};
    letter-spacing: ${props => props.theme.letterSpacing[0]};

    ${media.md`
      letter-spacing: 0;
      text-align: left;
      justify-content: flex-start;
      text-transform: ${props =>
        props.size === 'small' ? 'uppercase' : 'capitalize'};
      font-size: ${props =>
        props.size === 'small'
          ? props.theme.fontSizes[2]
          : props.theme.fontSizes[4]};
    `}

    ${media.xl`
        font-size: ${props =>
          props.size === 'small'
            ? props.theme.fontSizes[2]
            : props.theme.fontSizes[5]};
    `}
  }

  .list-content.list-content--date {
    text-transform: uppercase;
    font-size: ${props => props.theme.fontSizes[2]};

    ${media.lg`
            font-size: ${props =>
              props.size === 'small'
                ? props.theme.fontSizes[2]
                : props.theme.fontSizes[3]};
  `}

    ${media.xl`
            font-size: ${props =>
              props.size === 'small'
                ? props.theme.fontSizes[2]
                : props.theme.fontSizes[4]};
  `}
  }

  .list-content.list-content--actions {
  }
`

export default ListItemStyles
