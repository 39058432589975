import React from "react";
import Box from "src/components/UI/Structure/Box";
import styled from "styled-components";
import Swiper from "react-id-swiper";
import "swiper/swiper-bundle.css";

const Wrapper = styled(Box)`
  width: 100%;
  .swiper-container {
    width: 100%;
    padding-bottom: 40px;
  }
  width: 100%;
  .swiper-pagination {
    bottom: 0px !important;
  }
  .swiper-pagination-bullet {
    ${(props) =>
      props.theme.mixins.transition([
        { property: "background-color", time: "0.2s", easing: "linear" },
      ])};
    width: 6px;
    height: 6px;
    background: none;
    border: 1px solid #eaeaea;
    opacity: 1;
    &-active {
      transform: scale(1.4);
      background: black;
      border-color: black;
    }
  }
  &.light {
    .swiper-pagination-bullet-active {
      background: white;
      border-color: white;
    }
  }
  .swiper-slide {
    .animated-content {
      ${(props) =>
        props.theme.mixins.transition([
          { property: "opacity" },
          { property: "transform" },
        ])};
      transform: translateX(50px);
      opacity: 0.4;
    }
    &:last-child {
      .animated-content {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
  .swiper-slide-active {
    .animated-content {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .swiper-slide-prev {
    .animated-content {
      transform: translateX(-30px);
      opacity: 0.6;
    }
  }
`;

const defaultParams = {
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    type: "bullets",
  },
  slidesPerView: 1.5,
  slidesOffsetBefore: 32,
  slidesOffsetAfter: 32,
  spaceBetween: 32,
};

const Carousel = ({ children, params, className }) => (
  <Wrapper className={className}>
    <Swiper {...defaultParams} {...params}>
      {children}
    </Swiper>
  </Wrapper>
);

export default Carousel;
