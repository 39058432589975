import React, { Fragment } from "react";
import url from "url";
import Media from "src/components/Media";
import he from "he";

import Grid from "src/components/UI/Grid/Grid";
import Column from "src/components/UI/Grid/Column";
import Button from "src/components/UI/Button";
import Text from "src/components/UI/Text";
import ListItemStyles from "src/components/styles/ListItemStyles";
import LinkStyles from "src/components/styles/LinkStyles";

const ArtistItemList = ({ buttonText, country, link, name, territories }) => {
  const territoryAmount = territories ? territories.length : 0;
  const nameDecoded = he.decode(name);
  return (
    <ListItemStyles size="medium">
      <LinkStyles to={link ? url.parse(link).pathname : ""} title={nameDecoded}>
        <Grid
          flexDirection="row"
          flexWrap={"nowrap"}
          alignItems={["center"]}
          justifyContent={["space-between"]}
        >
          <Column width={territories ? ["auto", null, null, 4 / 12] : "auto"}>
            <div className="list-content list-content--title">
              <span>{nameDecoded}</span>
              {territories && (
                <Media
                  query="(min-width: 992px)"
                  render={() => (
                    <React.Fragment>
                      {country !== "none" ? (
                        <span
                          className="country-code"
                          style={{ fontWeight: "normal", marginLeft: "8px" }}
                        >{`(${country})`}</span>
                      ) : null}
                    </React.Fragment>
                  )}
                />
              )}
            </div>
          </Column>
          {!territories && (
            <Column width="auto" ml={["auto", null, null, 0]}>
              {country !== "none" ? (
                <div className="list-content">{`(${country})`}</div>
              ) : null}
            </Column>
          )}

          {territories && (
            <Column width={["auto", null, null, 4 / 12]}>
              <div className="list-content">
                <span>
                  {territories.map(({ territory }, i) => {
                    return (
                      <Fragment key={i}>
                        {territory}
                        {territoryAmount !== i + 1 && `, `}
                      </Fragment>
                    );
                  })}
                </span>
              </div>
            </Column>
          )}
          <Media
            query="(min-width: 992px)"
            render={() => (
              <Column
                ml="auto"
                width={[1, null, "188px", null, "188px", "264px"]}
              >
                <Button label={buttonText} width="1" textAlign="center">
                  <Text textAlign="center" width={1}>
                    {buttonText}
                  </Text>
                </Button>
              </Column>
            )}
          />
        </Grid>
      </LinkStyles>
    </ListItemStyles>
  );
};

export default ArtistItemList;
