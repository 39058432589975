import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Media from "src/components/Media";
import styled from "styled-components";

import Container from "src/components/UI/Grid/Container";
import Grid from "src/components/UI/Grid/Grid";
import Column from "src/components/UI/Grid/Column";
import Flex from "src/components/UI/Structure/Flex";
import Carousel from "src/components/Modules/Carousel/Carousel";
import ArtistItem from "src/components/Modules/Artists/ArtistItem";
import ArtistItemList from "src/components/Modules/Artists/ArtistItemList";
import Icon from "src/components/UI/Icon";
import IconPlus from "src/images/svg/icon-plus.svg";
import GridStyles from "src/components/styles/GridStyles";
import Label from "src/components/UI/Typography/Label";
import ListItemStyles from "src/components/styles/ListItemStyles";

const Item = styled(Flex)``;

const Artists = ({
  agency,
  artists,
  buttonText,
  carousel,
  layout,
  list,
  listLabels,
  overlay,
  readMore,
  readMoreText,
}) => {
  const ArtistsDesktop = ({ artists, readMore, readMoreText }) => {
    return (
      <Fragment>
        {!list && (
          <Container>
            <Grid justifyContent={layout}>
              {artists?.length > 0 &&
                artists.map(({ id = "", title = "", image, link, country }) => (
                  <Column key={id} width={[12 / 12, null, null, 4 / 12]}>
                    <ArtistItem
                      name={title}
                      image={image}
                      link={agency ? link.replace("artist", "agency") : link}
                      overlay={overlay}
                      country={country}
                    />
                  </Column>
                ))}
              {readMore && (
                <Column width={[12 / 12, null, null, 4 / 12]}>
                  <GridStyles>
                    <Link to="/artister">
                      <div className="grid-content">
                        <div className="grid-header" />
                        <div className="grid-body">
                          <Icon className="Grid__Icon" size="large">
                            <IconPlus />
                          </Icon>
                        </div>
                        <div className="grid-footer">
                          <Label>{readMoreText}</Label>
                        </div>
                      </div>
                    </Link>
                  </GridStyles>
                </Column>
              )}
            </Grid>
          </Container>
        )}

        {list && (
          <Container mt="-16px" px={[0, null, null, 4]}>
            {listLabels && (
              <ListItemStyles sticky label="true">
                <Grid
                  flexDirection="row"
                  flexWrap={"nowrap"}
                  alignItems={["center"]}
                  justifyContent={["space-between", null, null, "flex-start"]}
                >
                  {listLabels.map((label, i) => (
                    <Column width={label.width} key={i}>
                      <div
                        className="list-content list-content--label"
                        style={{ justifyContent: "flex-start" }}
                      >
                        {label.text}
                      </div>
                    </Column>
                  ))}
                </Grid>
              </ListItemStyles>
            )}
            {artists.map(({ id, title, link, country, territories }) => (
              <ArtistItemList
                key={`${id}`}
                buttonText={buttonText}
                name={title}
                link={agency ? link.replace("artist", "agency") : link}
                country={country}
                territories={territories}
              />
            ))}
          </Container>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {!carousel ? (
        <ArtistsDesktop
          artists={artists}
          readMore={readMore}
          readMoreText={readMoreText}
        />
      ) : (
        <Fragment>
          <Media
            query="(min-width: 991px)"
            render={() => {
              return (
                <ArtistsDesktop
                  artists={artists}
                  readMore={readMore}
                  readMoreText={readMoreText}
                />
              );
            }}
          />
          <Media
            query="(max-width: 991px)"
            render={() => (
              <Carousel>
                {artists.map((artist, i) => (
                  <Item width={1 / 3} key={i}>
                    <ArtistItem
                      mb={[0, null, 2]}
                      name={artist.title}
                      image={artist.image}
                      link={
                        agency
                          ? artist.link.replace("artist", "agency")
                          : artist.link
                      }
                      country={artist.country}
                      overlay={overlay}
                    />
                  </Item>
                ))}
              </Carousel>
            )}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Artists;

Artists.defaultProps = {
  buttonText: "Visa artist",
  readMoreText: "Visa alla artister",
};

Artists.propTypes = {
  readMore: PropTypes.bool,
  readMoreText: PropTypes.string,
};
